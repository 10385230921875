import { appendUrlParams } from "utils/urlUtils";
import {
  TEMPLATE_UUID_PARAM,
  SALES_PAGE_UUID_PARAM,
} from "ui/pages/SalesPages/constants";
import { sales_pages_apis } from "ui/pages/SalesPages/API";
import { default_sales_page_stage_id } from "ui/pages/SalesPages/pages/SalesPagesHome/constants";

export const SALES_PAGES_ROUTE_KEYS = {
  sales_pages_home: "sales_pages_home",
  sales_pages_update_template: "sales_pages_update_template",
  sales_pages_update_details: "sales_pages_update_details",
  sales_pages_preview: "sales_pages_preview",
};

export const SALES_PAGES_ROUTE_IDS = {
  [SALES_PAGES_ROUTE_KEYS.sales_pages_home]: "sales-pages",
  [SALES_PAGES_ROUTE_KEYS.sales_pages_update_template]:
    "sales-pages/udpate/:sale_page_uuid/template",
  [SALES_PAGES_ROUTE_KEYS.sales_pages_update_details]:
    "/sales-pages/update/details",
  [SALES_PAGES_ROUTE_KEYS.sales_pages_preview]:
    "sales-pages/preview/:sale_page_uuid",
};

export const SALES_PAGES_ROUTE_GENERATORS = {
  [SALES_PAGES_ROUTE_KEYS.sales_pages_update_template]: ({ salePageUuid }) =>
    `sales-pages/udpate/${salePageUuid}/template`,
  [SALES_PAGES_ROUTE_KEYS.sales_pages_update_details]: ({
    salePageUuid,
    templateUuid,
  }) =>
    appendUrlParams(`/sales-pages/update/details`, {
      [TEMPLATE_UUID_PARAM]: templateUuid,
      [SALES_PAGE_UUID_PARAM]: salePageUuid,
    }),
  [SALES_PAGES_ROUTE_KEYS.sales_pages_preview]: ({ salePageUuid }) =>
    `sales-pages/preview/${salePageUuid}`,
};

export const SALES_PAGES_RESOURCE_APIS = {
  [SALES_PAGES_ROUTE_IDS[SALES_PAGES_ROUTE_KEYS.sales_pages_home]]: ({
    params,
  }) => {
    const queries = { ...params?.filter };
    queries[sales_page_home_stage_filter_key] =
      queries[sales_page_home_stage_filter_key] ?? default_sales_page_stage_id;

    return {
      api_end_point: sales_pages_apis.get_sales_pages_list,
      queries,
    };
  },
};

export const sales_page_detail_keys = {
  uuid: "uuid",
  sales_page_stage: "sales_page_stage",
  listing_uid: "listing_uid",
  redirect_url: "redirect_url", // redirect url when register button is clicked.. leads to listing / checkout page
  sales_page_url: "sales_page_url",
  page_builder_source: "page_builder_source",
  template_uid: "template_uid",
  url_slug: "url_slug",
  metadata: "metadata",
};
export const sales_page_metadata_keys = { cdn_url: "cdn_url" };

export const sales_page_home_stage_filter_key =
  sales_page_detail_keys.sales_page_stage;

/**
 * refer TemplateDataInterface
 */
export const TEMPLATE_DETAIL_KEYS = {
  uuid: "uuid",
  slug: "slug",
  page_builder_source: "page_builder_source",
};

/**
 * depicts what builder was used for creating the page
 */
export const GJS_PAGE_BUILDER_SOURCES = {
  exly: 1,
  grapes_js: 2,
};

/**
 * Sale Page Section Slugs
 * Todo: More to be added here
 */
export const SALE_PAGE_SECTION_SLUGS = {
  content_catalogue: "ContentCatalogue",
};
