import CustomLinkIcon from "assets/vectors/payment_links/custom_link_option_icon.svg";
import OpenLinkIcon from "assets/vectors/payment_links/open_link_option_icon.svg";
import { generateLinkTypeOption } from "../utils/CreatePaymentLinkModal.utils";

export const LINK_TYPES = {
  CUSTOM_LINK: 1,
  OPEN_LINK: 2,
  UNIVERSAL_LINK: 3,
};

export const LINK_TYPE_LABELS = {
  [LINK_TYPES.CUSTOM_LINK]: "Fixed Payment Page",
  [LINK_TYPES.OPEN_LINK]: "Flexible Payment Page",
  [LINK_TYPES.UNIVERSAL_LINK]: "Universal Payment Page",
};

export const LINK_TYPE_OPTIONS = [
  generateLinkTypeOption({
    title: LINK_TYPE_LABELS[LINK_TYPES.OPEN_LINK],
    description: "Use to create links where your customers can pay any price",
    value: LINK_TYPES.OPEN_LINK,
    icon: OpenLinkIcon,
  }),
  generateLinkTypeOption({
    title: LINK_TYPE_LABELS[LINK_TYPES.CUSTOM_LINK],
    description:
      "Use to create custom payment links different than the original price",
    value: LINK_TYPES.CUSTOM_LINK,
    icon: CustomLinkIcon,
  }),
];

// value represent the page number here, so page values must be in ascending order with the difference of 1
export const CREATE_PAYMENT_LINK_FORM_PAGES = {
  LINK_TYPE_SELECTION: 1,
  FORM_INPUTS: 2,
};

export const CREATE_PAYMENT_LINK_MODAL_TITLES = {
  [LINK_TYPES.OPEN_LINK]: "Create Flexible Payment Page",
  [LINK_TYPES.CUSTOM_LINK]: "Create Fixes Payment Page",
};

export const CREATE_PAYMENT_LINK_MODAL_CTA_LABELS = {
  [CREATE_PAYMENT_LINK_FORM_PAGES.LINK_TYPE_SELECTION]: "Continue",
  [CREATE_PAYMENT_LINK_FORM_PAGES.FORM_INPUTS]: "Create Link",
};

export const FORM_INPUTS_ERROR_MESSAGES = {
  link_name_length: "Title must be between 3 and 100 characters",
  link_price_required: "Link price is mandatory",
  link_name_required: "Link name is mandatory",
  required: "Required",
};

export const CREATE_PAYMENT_LINK_EVENTS = {
  LINK_CREATED_SUCCESS: 1,
  LINK_UPDATED_SUCCESS: 2,
};

export const CREATE_PAYMENT_LINK_EVENT_MESSAGES = {
  [CREATE_PAYMENT_LINK_EVENTS.LINK_CREATED_SUCCESS]:
    "Dynamic link created. You can see the link in the 'Manage Payment Pages' table.",
  [CREATE_PAYMENT_LINK_EVENTS.LINK_UPDATED_SUCCESS]:
    "Payment page has been successfully updated",
};
