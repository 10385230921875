import {
  LINK_TYPE_LABELS,
  LINK_TYPES,
} from "../../CreatePaymentLinkModal/constants/CreatePaymentLinkModal.constants";
import {
  formatLinkDate,
  formatLinkPrice,
  formatLinkType,
  formatPaymentLink,
  formatTotalBookings,
} from "../utils/ExistingPaymentLinks.utils";

export const EXISTING_PAYMENT_LINKS_IMAGE_ASSETS = {
  empty_state: "existing_payment_links.png",
};

export const EXISTING_PAYMENT_LINKS_COLUMN_KEYS = {
  link_title: "link_title",
  payment_link_type: "payment_link_type",
  link_price: "link_price",
  pop_name: "pop_name",
  original_price: "original_price",
  date_created: "date_created",
  expiry_date: "expiry_date",
  link: "link",
  total_leads: "total_leads",
  total_bookings: "total_bookings",
  total_revenue: "total_revenue",
};

export const EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS = {
  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.payment_link_type]: formatLinkType,

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.date_created]: formatLinkDate(
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.date_created as keyof typeof EXISTING_PAYMENT_LINKS_COLUMN_KEYS
  ),

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.expiry_date]: formatLinkDate(
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.expiry_date as keyof typeof EXISTING_PAYMENT_LINKS_COLUMN_KEYS
  ),

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link]: formatPaymentLink,

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_price]: formatLinkPrice(
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_price as keyof typeof EXISTING_PAYMENT_LINKS_COLUMN_KEYS
  ),

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.original_price]: formatLinkPrice(
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.original_price as keyof typeof EXISTING_PAYMENT_LINKS_COLUMN_KEYS
  ),

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_bookings]: formatTotalBookings,

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_revenue]: formatLinkPrice(
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_revenue as keyof typeof EXISTING_PAYMENT_LINKS_COLUMN_KEYS
  ),
};

const EXISTING_PAYMENT_LINKS_COLUMNS = {
  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_title]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_title,
    headerName: "Page name",
    emptyField: "N/A",
    noWrap: true,
    ellipsis: true,
    maxCharCount: 30,
    fixed: "left",
    isPrimary: true,
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.payment_link_type]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.payment_link_type,
    headerName: "Page type",
    noWrap: true,
    valueFormatter:
      EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
        EXISTING_PAYMENT_LINKS_COLUMN_KEYS.payment_link_type
      ],
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.pop_name]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.pop_name,
    headerName: "Offering",
    noWrap: true,
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.original_price]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.original_price,
    headerName: "Original price",
    noWrap: true,
    valueFormatter:
      EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
        EXISTING_PAYMENT_LINKS_COLUMN_KEYS.original_price
      ],
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_price]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_price,
    headerName: "Price on page",
    noWrap: true,
    emptyField: "N/A",
    valueFormatter:
      EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
        EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_price
      ],
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.date_created]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.date_created,
    headerName: "Created on",
    noWrap: true,
    emptyField: "N/A",
    valueFormatter:
      EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
        EXISTING_PAYMENT_LINKS_COLUMN_KEYS.date_created
      ],
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.expiry_date]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.expiry_date,
    headerName: "Expires on",
    noWrap: true,
    emptyField: "N/A",
    valueFormatter:
      EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
        EXISTING_PAYMENT_LINKS_COLUMN_KEYS.expiry_date
      ],
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link,
    headerName: "Page link",
    noWrap: true,
    hidden: true,
    valueFormatter:
      EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
        EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link
      ],
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_leads]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_leads,
    headerName: "Leads",
    noWrap: true,
    emptyField: "0",
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_bookings]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_bookings,
    headerName: "Bookings",
    noWrap: true,
    exptyField: "N/A",
    valueFormatter:
      EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
        EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_bookings
      ],
  },

  [EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_revenue]: {
    field: EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_revenue,
    headerName: "Revenue",
    noWrap: true,
    exptyField: "N/A",
    valueFormatter:
      EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
        EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_revenue
      ],
  },
};

export const EXISTING_PAYMENT_LINKS_COLUMN_CONFIG = [
  EXISTING_PAYMENT_LINKS_COLUMNS[EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_title],

  EXISTING_PAYMENT_LINKS_COLUMNS[
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.payment_link_type
  ],

  EXISTING_PAYMENT_LINKS_COLUMNS[EXISTING_PAYMENT_LINKS_COLUMN_KEYS.pop_name],

  EXISTING_PAYMENT_LINKS_COLUMNS[
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.original_price
  ],

  EXISTING_PAYMENT_LINKS_COLUMNS[EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_price],

  EXISTING_PAYMENT_LINKS_COLUMNS[
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.date_created
  ],

  EXISTING_PAYMENT_LINKS_COLUMNS[
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.expiry_date
  ],

  EXISTING_PAYMENT_LINKS_COLUMNS[EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link],

  EXISTING_PAYMENT_LINKS_COLUMNS[
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_leads
  ],

  EXISTING_PAYMENT_LINKS_COLUMNS[
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_bookings
  ],

  EXISTING_PAYMENT_LINKS_COLUMNS[
    EXISTING_PAYMENT_LINKS_COLUMN_KEYS.total_revenue
  ],
];

export const EXISTING_PAYMENT_LINKS_FILTER_KEYS = {
  listing__title: "listing__title",
  created_at__start_range: "created_at__start_range",
  expiry_date__start_range: "expiry_date__start_range",
  payment_link_type: "payment_link_type",
};

export const EXISTING_PAYMENT_LINKS_FILTER_CONFIG = [
  {
    source: EXISTING_PAYMENT_LINKS_FILTER_KEYS.payment_link_type,
    placeholder: "Page Type",
    type: "dropdown",
    options: [
      {
        id: 1,
        label: LINK_TYPE_LABELS[LINK_TYPES.OPEN_LINK],
        value: LINK_TYPES.OPEN_LINK,
      },
      {
        id: 2,
        label: LINK_TYPE_LABELS[LINK_TYPES.CUSTOM_LINK],
        value: LINK_TYPES.CUSTOM_LINK,
      },
    ],
  },
  {
    source: EXISTING_PAYMENT_LINKS_FILTER_KEYS.listing__title,
    placeholder: "Offering",
    type: "input",
  },
  {
    source: EXISTING_PAYMENT_LINKS_FILTER_KEYS.created_at__start_range,
    placeholder: "Created On",
    type: "date",
  },
  {
    source: EXISTING_PAYMENT_LINKS_FILTER_KEYS.expiry_date__start_range,
    placeholder: "Expires On",
    type: "date",
  },
];
